import './App.css';
import { useEffect, useState } from "react";

function App() {
  const [data, setData] = useState([]);
  const [dataInizio, setDataInizio] = useState(new Date().toISOString());
  const [dataFine, setDataFine] = useState(new Date().toISOString());

  function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  //create function get data from strapi backend
  const getData = async () => {
    const response = await fetch('https://contabilita.eticoshop.it/api/acquistis?populate=*&filters[data_acquisto][$gte]=' + dataInizio + '&filters[data_acquisto][$lte]=' + dataFine);
    const data = await response.json();
    console.log(data);
    setData(data.data);
  }

  useEffect(() => {
    getData();
  }, []);



  return (
    <div className="App">

      <h2>Contabilità Etico / Valleverde</h2>
      <label>Data inizio</label><br></br>
      <input onChange={(e) => setDataInizio(new Date(e.target.value).toISOString())} type="date"></input><br></br>
      <label>Data fine</label><br></br>
      <input onChange={(e) => setDataFine(new Date(e.target.value).toISOString())} type="date"></input>
      <br /><button onClick={getData}>Cerca</button>
      <div style={{ display: "flex", padding: "5px 25px 5px 25px" }}>
        <div style={{ border: "1px solid red", width: "25%" }}>
          <b>Contanti</b><br />
          <b>Valleverde:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'contanti') ? sum : sum + record.attributes.totale_valleverde;
          }, 0)
          }<br />
          <b>Etico:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'contanti') ? sum : sum + record.attributes.totale_etico;
          }, 0)
          }<br />
          <b>Totale:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'contanti') ? sum : sum + record.attributes.totale;
          }, 0)
          }<br />
        </div>
        <div style={{ border: "1px solid red", width: "25%" }}>
          <b>Non Pagato</b><br />
          <b>Valleverde:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'non_pagato') ? sum : sum + record.attributes.totale_valleverde;
          }, 0)
          }<br />
          <b>Etico:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'non_pagato') ? sum : sum + record.attributes.totale_etico;
          }, 0)
          }<br />
          <b>Totale:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'non_pagato') ? sum : sum + record.attributes.totale;
          }, 0)
          }<br />

        </div>
        <div style={{ border: "1px solid red", width: "25%" }}>
          <b>Tally</b><br />
          <b>Valleverde:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'tally') ? sum : sum + record.attributes.totale_valleverde;
          }, 0)
          }<br />
          <b>Etico:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'tally') ? sum : sum + record.attributes.totale_etico;
          }, 0)
          }<br />
          <b>Totale:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'tally') ? sum : sum + record.attributes.totale;
          }, 0)
          }<br />

        </div>
        <div style={{ border: "1px solid red", width: "25%" }}>
          <b>Bonifico</b><br />
          <b>Valleverde:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'bonifico') ? sum : sum + record.attributes.totale_valleverde;
          }, 0)
          }<br />
          <b>Etico:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'bonifico') ? sum : sum + record.attributes.totale_etico;
          }, 0)
          }<br />
          <b>Totale:</b>{data.reduce((sum, record) => {
            return (record.attributes.metodo_pagamento !== 'bonifico') ? sum : sum + record.attributes.totale;
          }, 0)
          }<br />

        </div>
      </div>
      <div>
        <h3>Totale Venduto</h3>
        <b>Valleverde:</b>{data.reduce((sum, record) => {
          return sum + record.attributes.totale_valleverde;
        }, 0)
        }<br />
        <b>Etico:</b>{data.reduce((sum, record) => {
          return sum + record.attributes.totale_etico;
        }, 0)
        }<br />
        <b>Totale:</b>{data.reduce((sum, record) => {
          return sum + record.attributes.totale;
        }, 0)
        }<br />
      </div>
      {
        data.sort((a, b) => a.attributes.clienti.data.attributes.Nome.localeCompare(b.attributes.clienti.data.attributes.Nome)).map(acquisto => acquisto.attributes.metodo_pagamento == "non_pagato" && <div style={{ marginTop: "15px" }}>
          <b>Cliente:</b> {acquisto.attributes.clienti.data.attributes.Cognome + " " + acquisto.attributes.clienti.data.attributes.Nome}<br />
          <b>Data: </b>{acquisto.attributes.data_acquisto}<br />
          <b>Totale:</b> {acquisto.attributes.totale}
        </div>)
      }
    </div>
  );
}

export default App;
